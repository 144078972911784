var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom bg-gray-100 card-stretch gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body p-0 position-relative overflow-hidden"},[_c('div',{staticClass:"card-spacer"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-warning d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Media/Equalizer.svg"}})],1),_c('a',{staticClass:"text-warning font-weight-bold font-size-h6",attrs:{"href":"#"}},[_c('b',[_vm._v("SETTORE:")]),_vm._v(" "+_vm._s(_vm.sector)+" "),_c('span',{ref:"ssss"})])]),_c('div',{staticClass:"col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-warning d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Media/Equalizer.svg"}})],1),_vm._m(1)]),_c('div',{staticClass:"col bg-light-primary px-6 py-8 rounded-xl mb-7"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Add-user.svg"}})],1),_vm._m(2)])]),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col bg-light-danger px-6 py-8 rounded-xl mr-7"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-danger d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Layers.svg"}})],1),_vm._m(3)]),_c('div',{staticClass:"col bg-light-danger px-6 py-8 rounded-xl mr-7"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-danger d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Layers.svg"}})],1),_vm._m(4)]),_c('div',{staticClass:"col bg-light-success px-6 py-8 rounded-xl"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-success d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Urgent-mail.svg"}})],1),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0 bg-danger py-5"},[_c('h3',{staticClass:"card-title font-weight-bolder text-white"},[_vm._v(" Cacao N.1 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-warning font-weight-bold font-size-h6",attrs:{"href":"#"}},[_c('b',[_vm._v("CALENDAR SPREAD:")]),_vm._v(" 2 Gambe ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-primary font-weight-bold font-size-h6 mt-2",attrs:{"href":"#"}},[_c('b',[_vm._v("PERIODO DI TRADING:")]),_vm._v(" Ott -> Nov ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-danger font-weight-bold font-size-h6 mt-2",attrs:{"href":"#"}},[_c('b',[_vm._v("TIPO:")]),_vm._v(" Intramarket ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-danger font-weight-bold font-size-h6 mt-2",attrs:{"href":"#"}},[_c('b',[_vm._v("RISCHIO:")]),_vm._v(" Medio ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-success font-weight-bold font-size-h6 mt-2",attrs:{"href":"#"}},[_c('b',[_vm._v("SCADENZE:")]),_vm._v(" Estive ")])
}]

export { render, staticRenderFns }